import Store from '../../../store';
import { levels } from '../../service-plan/tools/enums';

const LandingPage = () => import('../views/LandingPage.vue');
const LandingHeader = () => import('@/modules/login-extended/components/LandingHeader.vue');
const AccountFormsView = () => import('@/modules/login-extended/views/AccountFormsView.vue');
const RegisterForm = () => import('@/modules/login-extended/components/RegisterForm.vue');
const StartPasswordRecoveryForm = () => import('@/modules/login-extended/components/StartPasswordRecoveryForm.vue');
const ResetPasswordForm = () => import('@/modules/login-extended/components/ResetPasswordForm.vue');
const LoginForm = () => import('@/modules/login-extended/components/LoginFormHorizontal.vue');

export default [
  {
    path: '/',
    name: 'landing',
    meta: { guestOnly: true },
    components: {
      default: LandingPage,
      header: LandingHeader,
    },
    props: {
      default: true,
      header: (route) => ({
        companyLogo: process.env.VUE_APP_COMPANY_LOGO,
        partnerLogo: process.env.VUE_APP_PARTNER_LOGO,
      }),
    },
  },

  {
    path: '/account',
    name: 'account',
    meta: { guestOnly: true },
    components: {
      default: AccountFormsView,
    },
    children: [
      {
        path: 'login',
        alias: '/login',
        name: 'account.login',
        component: LoginForm,
        props: {
          vertical: true,
          enableRecaptcha: false,
        },
      },
      {
        path: 'register',
        name: 'account.register',
        component: RegisterForm,
        props: (route) => {
          return {
            enableRecaptcha: true,
            store: 'login-static-plans',
            targetSubscription: levels.LEVEL0,
          };
        },
      },
      {
        path: 'password-recovery/:email?',
        name: 'account.passwordRecoveryRequest',
        component: StartPasswordRecoveryForm,
        props: (route) => {
          const { email } = route.params;
          return {
            routeEmail: email,
            enableRecaptcha: true,
            store: 'login-static-plans'
          };
        },
      },
      {
        path: 'password-recovery/:email/:token',
        name: 'account.passwordRecovery',
        component: ResetPasswordForm,
        props: (route) => {
          const { email, token } = route.params;
          return {
            email,
            token,
            enableRecaptcha: false,
            store: 'login-static-plans'
          };
        },
      },
    ],
  },
];
