import Vue from 'vue';
import reduce from 'lodash/reduce';
import { Client } from '@/services';
import vuetify from '../../../plugins/vuetify';
import { Services } from '../../../store/actions/api';
import { jsonToFormData } from '../../../utils/http-common';
import { ErrorType, ResponseType } from '../../../constants';
import { captureException } from '@sentry/vue';

// Used only to simulate network request instead of real request
function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
  namespaced: true,
  // state() {
  //   return {};
  // },
  // getters: {},
  // mutations: {},
  actions: {
    initialize({ dispatch }) {
      console.info('LoginExtended initializing...');
      console.info('LoginExtended initialized.');
    },

    async emailExist({ dispatch }, { email, rcToken }) {
      try {
        const params = {
          service: Services.FlightScopeUser.key,
          method: Services.FlightScopeUser.methods.EmailExist.key,
        };

        let payload = {
          Email: email,
        };

        if (rcToken) {
          payload.RC_ResponseToken = rcToken;
        }

        const data = jsonToFormData(payload);

        const response = await Client({ data, method: 'post', params });

        return response.data;
      } catch (e) {
        captureException(e);
        Vue.$log.debug('There was errors during checking if email exists.');
        return false;
      }
    },

    async registerUser(
      { dispatch },
      { email, firstName, lastName, displayName, password, consents, genderID, targetSubscription, strict, rcToken },
    ) {
      try {
        const params = {
          service: Services.FlightScopeUser.key,
          method: Services.FlightScopeUser.methods.InsertUser.key,
        };

        const consentsJson = reduce(consents, (acc, { name, value }) => ({ ...acc, [name]: value }), {});

        const payload = {
          Email: email,
          DisplayName: JSON.stringify({
            DisplayName: displayName,
            FirstName: firstName,
            LastName: lastName,
          }),
          Password: password,
          GenderID: genderID,
          Strict: strict,
          ParametersJSON: JSON.stringify({
            ...consentsJson,
            targetSubscription,
          }),
        };

        if (rcToken) {
          payload.RC_ResponseToken = rcToken;
        }

        const formDataPayload = jsonToFormData(payload);

        await Client({
          method: Services.FlightScopeUser.methods.InsertUser.type,
          params,
          data: formDataPayload,
        });

        return { type: ResponseType.SUCCESS };
      } catch (error) {
        captureException(error);
        Vue.$log.debug('There was errors during inserting user.');
        return {
          type: error.response.type,
          message: vuetify.framework.lang.t('$vuetify.alerts.request_error'),
        };
      }
    },

    async startPasswordRecovery({ dispatch }, { email, rcToken }) {
      try {
        const params = {
          service: Services.FlightScopeUser.key,
          method: Services.FlightScopeUser.methods.RequestPasswordReset.key,
        };

        const payload = {
          Email: email,
        };

        if (rcToken) {
          payload.RC_ResponseToken = rcToken;
        }

        const data = jsonToFormData(payload);

        const response = Client({ method: 'post', data, params });

        return { type: ResponseType.SUCCESS };
      } catch (error) {
        captureException(error);
        return { type: ErrorType.SERVICE_ERROR };
      }
    },
    async passwordRecovery({ dispatch }, { email, token, password }) {
      try {
        const params = {
          service: Services.FlightScopeUser.key,
          method: Services.FlightScopeUser.methods.ResetPassword.key,
        };

        const payload = {
          Email: email,
          Password: password,
          Key: token,
        };

        const data = jsonToFormData(payload);

        const response = await Client({ method: 'post', data, params });

        return { type: ResponseType.SUCCESS };
      } catch (error) {
        captureException(error);
        return { type: ErrorType.SERVICE_ERROR };
      }
    },
  },
};
